<template>
  <div class="custome-input-wrap">
    <span>{{inputTitle}} <i>*</i></span>
    <div class="input-wrap">
      <el-tooltip
        :content="tips"
        :disabled="tips === '' || tips == null"
        placement="top-start"
        trigger="focus"
      >
        <input v-bind="$attrs" :value="value" @input="handleInput" @focus="errorTipInner=''" @blur="handleBlur"/>
      </el-tooltip>
      <div class="error-tip" v-show="errorTipInner">{{errorTipInner}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomeInput',
  props: {
    errorTip: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    inputTitle: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
      valueInner: '',
      errorTipInner: ''
    }
  },
  mounted () {
    this.valueInner = this.value
    this.errorTipInner = this.errorTip
  },
  methods: {
    handleInput (e) {
      this.valueInner = e.target.value + ''
    },
    handleBlur () {
      this.errorTipInner = this.errorTip
      this.$emit('blur')
    }
  },
  watch: {
    valueInner (val) {
      this.$emit('input', val)
    },
    errorTip (val) {
      this.errorTipInner = val
    }
  }
}
</script>
<style lang="scss" scoped>
.custome-input-wrap {
  display: flex;
  span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    display: inline-block;
    width: 80px;
    text-align: right;
    i {
      color: #ff0000;
    }
  }
  .input-wrap{
    flex: 1;
    height: 44px;
    position: relative;
    margin-left: 24px;
    input{
      padding-left: 12px;
      display: block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,0.30);
      border-radius: 2px;
    }
  }
  .error-tip{
    position: absolute;
    bottom: -24px;
    font-size: 14px;
    color: #FA3239;
    line-height: 20px;
  }
}
</style>

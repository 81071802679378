import { eitsHttp, http } from './httpInstance'
// import eitsHttp from './eitsHttp'

/** 获取用户基本信息 */
export function getUserBasic () {
  return eitsHttp.post('portal/api/eits/v1/user/basic/get', {}, { noMsg: true })
}

/** 获取用户详细信息 */
export function getUserDetail (params) {
  return eitsHttp.post('portal/api/eits/v1/user/detail/get', params, { noMsg: true })
}

// 获取短信验证码【绑定手机号】
export function getVerifyCodeForBindMobile (params) {
  return eitsHttp.post('portal/api/eits/v1/user/phone/verifyCode', params, { successNotify: true })
}
// 获取挑战码
export function challengeCode (params) {
  return eitsHttp.post('portal/api/eits/v1/challengeCode', params)
}

// 主动修改密码
export function updatePwd (params) {
  return eitsHttp.post('portal/api/eits/v1/user/pwd/update', params, { showLoading: true })
}

// 判断手机号是否注册过【通过滑块触发】
export function getRegisterStatus (params) {
  return eitsHttp.post('portal/api/eits/v1/phone/status', params)
}

// 获取短信验证码【忘记密码】
export function getVerifyCodeForForget (params) {
  return eitsHttp.post('portal/api/eits/v1/phone/verifyCode', params, { successNotify: true, showLoading: true })
}

// 校验短信验证码【忘记密码】
export function checkVerifyCodeForForget (params) {
  return eitsHttp.post('portal/api/eits/v1/phone/checkCode', params)
}

// 忘记密码
export function changePwd (params) {
  return eitsHttp.post('portal/api/eits/v1/changePwd', params, {
    showLoading: true
  })
}

// 登出
export function logout (params) {
  return eitsHttp.post('portal/api/eits/v1/logout', params)
}

export function getUser () {
  return http.get('userInfo')
}
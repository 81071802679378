<template>
  <div class="reset-step2-container">
    <img src="../../assets/images/operate_success.png" alt="" />
    <div class="success-tip">{{tipTitle}}</div>
    <el-button type="primary" @click="goLoginPage">返回登录</el-button>
    <div class="success-count-down">{{count}}秒后自动返回登录</div>
  </div>
</template>
<script>
export default {
  props: {
    tipTitle: {
      type: String,
      default: '密码重置成功!'
    }
  },
  data () {
    return {
      count: 3,
      timer: null
    }
  },
  mounted () {
    this.countDown()
  },
  methods: {
    countDown () {
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--
        } else {
          this.count = 0
          clearInterval(this.timer)
          this.goLoginPage()
        }
      }, 1000)
    },
    goLoginPage () {
      location.href = '/login'
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
<style lang="scss" scoped>
.reset-step2-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 96px;
    height: 96px;
    margin: 134px 0 24px;
  }
  .success-tip {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
    line-height: 25px;
  }
  button {
    width: 400px;
    height: 48px;
    font-size: 18px;
    margin: 22px 0 24px;
    max-width: initial;
    border-radius: 4px;
  }
  .success-count-down {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 160px;
  }
}
</style>
